import React, { useState, useEffect } from "react";
import { Typography, Grid, Button } from "@mui/material";
import StatsCard from "./Common/StatsCard";
import axios from "axios";
import { ethers } from "ethers";
import rewardAbi from "./abi/abi.json";
import setting from "./settings.json";
import { useSigner, useAddress } from "@thirdweb-dev/react";
import { toast } from "react-toastify";

function Rewards() {
  const BASE_URL = "https://cdn-depin.vercel.app";

  const address = useAddress();

  const signer = useSigner();

  const [stats, setStats] = useState(null);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [rewardsClaimed, setRewardClaimed] = useState(0);

  const claimRewards = async () => {
    let toastId = null;
    try {
      const rewardContract = new ethers.Contract(
        setting.REWARD_CONTRACT,
        JSON.parse(rewardAbi.result),
        signer
      );

      toastId = toast.loading(`Transaction Processing`);

      const claimTransaction = await rewardContract
        .connect(signer)
        .claimRewards();

      const txn = await claimTransaction.wait();

      console.log(txn, "txn");

      toast.update(toastId, {
        render: `Transaction Successful ${txn.transactionHash}`,
        type: "success",
        isLoading: false,
        autoClose: 3000,
        draggable: true,
      });
    } catch (error) {
      let err = "";

      if (error instanceof Error) {
        if (ethers.errors.ACTION_REJECTED === error.code) {
          err = "Transaction rejected by the user";
        } else if (ethers.errors.UNPREDICTABLE_GAS_LIMIT === error.code) {
          err = error.error.message;
        }
        console.log(error.message);
      } else {
        console.error("An unknown error occurred while sending native token");
        err = "An unknown error occurred";
      }

      toast.update(toastId, {
        render: `${err}`,
        type: "error",
        isLoading: false,
        autoClose: 3000,
        draggable: true,
      });
    }
  };

  async function fetchStatsData(address) {
    try {
      const rewardContract = new ethers.Contract(
        setting.REWARD_CONTRACT,
        JSON.parse(rewardAbi.result),
        signer
      );

      const userRewards = await rewardContract.userRewards(address);

      setRewardClaimed(Number(userRewards.rewardsClaimed));

      const response = await axios.get(`${BASE_URL}/stats/${address}`);
      console.log(response.data);
      if (response.data.status === 200) {
        setStats(response.data.data);
      } else {
        setError(true);
        setErrorMsg(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    if (address) {
      fetchStatsData(address);
    }
  }, [address]);

  return (
    <>
      <div className="bg-img">
        <div className="content">
          {address && !error ? (
            <>
              <Grid sx={{ mt: 6, p: 2 }}>
                <Typography
                  sx={{ color: "white", textAlign: "center" }}
                  variant="h5"
                >
                  User Stats
                </Typography>
              </Grid>
              <Grid container spacing={4} sx={{ mb: 6, p: 2 }}>
                <Grid item md={4}>
                  <StatsCard
                    title="Total Runtime"
                    value={stats?.totalActiveTime}
                  />
                </Grid>
                <Grid item md={4}>
                  <StatsCard
                    title="Total Rewards"
                    value={stats?.totalRewards}
                  />
                </Grid>
                <Grid item md={4}>
                  <StatsCard
                    title="Total Rewards Claimed"
                    value={rewardsClaimed}
                  />
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    color: "black",
                    background: "#ffe84c",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#ffe84c",
                    },
                  }}
                  onClick={claimRewards}
                >
                  Claim {stats?.totalRewards - rewardsClaimed} Rewards
                </Button>
              </Grid>{" "}
            </>
          ) : (
            <Grid sx={{ mt: 20, p: 2 }}>
              <Typography
                sx={{ color: "white", textAlign: "center" }}
                variant="h2"
              >
                {errorMsg ? errorMsg : "Connect wallet to claim rewards"}
              </Typography>
            </Grid>
          )}
        </div>
      </div>
    </>
  );
}

export default Rewards;
