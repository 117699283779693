// import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import ArchiveBoxIcon from "@heroicons/react/24/outline/ChartBarSquareIcon";
import GifIcon from "@heroicons/react/24/solid/GiftIcon";
// import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import { SvgIcon } from "@mui/material";

export const items = [
  {
    title: "Dashboard",
    path: "/",
    icon: (
      <SvgIcon fontSize="small">
        <ArchiveBoxIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Rewards",
    path: "/rewards",
    icon: (
      <SvgIcon fontSize="small">
        <GifIcon />
      </SvgIcon>
    ),
  },
];
